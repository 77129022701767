/** @format */

.detail-container {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 3fr 1.5fr;
  grid-template-areas:
    "camera data"
    "graph graph";

  .camera {
    grid-area: camera;
  }

  .data {
    grid-area: data;
  }
  .graph {
    grid-area: graph;
  }
}
@media only screen and (max-width: 1000px) {
  .detail-container {
    display: grid;
    grid-gap: 1rem;
    grid-template-areas:
      "camera"
      "data"
      "graph";

    .camera {
      width: 93vw;
      min-height: 28vh;
      grid-area: camera;
    }

    .data {
      grid-area: data;
    }
    .graph {
      grid-area: graph;
    }
  }
}

.spinner-container {
  width: calc(100% - 2.5rem);
  height: calc(100% - 2.5rem);
  padding: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 0;
  .spinner {
    animation: spin 2s linear infinite;
    color: #7a7fdc;
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
