/** @format */

.calender > .rdtPicker {
  right: 20px;
}

.modal-dialog .modal-xl {
  width: 90vw !important;
}

.search_modal {
  display: flex;
  justify-content: center;
  &.modal-content {
    width: 90vw;
  }
}

.search_modal > .modal-content {
  width: 90vw;
}
