/** @format */

.date {
  padding: 0.4rem;
  width: 200px;
}

.selector {
  display: inline-block;
  width: 150px;
}

.err {
  width: 70%;
}

#search_date {
  font-weight: bolder;
}

.machine_img {
  cursor: pointer;
}

.machine_img:hover {
  transform: scale(1.1);
  transition: all 0.6s;
}

.empty_img {
  max-width: 200px;
  opacity: 0.7;
}
